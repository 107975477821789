input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}
.larger-modal {
  width: 50vw;
  align-self: center;
}
.invoiceForecastTable tbody tr:last-child td {
  font-weight: bold;
}
.serviceDeskTable tbody tr:last-child td {
  font-weight: bold;
}
.absolute {
  position: absolute;
}
.projects-filter-header {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.w-1\/2 {
  width: 50%;
}
.w-1\/4 {
  width: 25%;
}
.w-1\/3 {
  width: 33%;
}
.w-1\/5 {
  width: 20%;
}
.w-1\/8 {
  width: 12.5%;
}
.w-1\/10 {
  width: 10%;
}
.w-1\/20 {
  width: 5%;
}
.w-5\/4 {
  width: 80%;
}
.w-2\/3 {
  width: 33%;
}
.w-3\/4 {
  width: 75%;
}
.w-full {
  width: 100%;
}
